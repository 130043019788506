<template>
    <div style="height: 100%;">
      <h1 class="heading">Sticker Constructor</h1>
        <div class="container_editor">
            <div class="components">

              <div class="components_basic">
                <label class="label">Paper format</label>
                <v-select outlined dense hide-small></v-select>
<!--                //A4,A3-->
              </div>
                <div class="components_basic">
                    <label class="label" @click="showComponents">Components <v-icon>{{componentsBasicShow?'mdi-chevron-up':'mdi-chevron-down'}}</v-icon></label>
                    <div class="components_basic_list" v-if="componentsBasicShow">
                        <div class="components_basic_item" @click="handleFileImportBackground">
                            <div class="components_basic_item_img">
                                <v-icon>mdi-cellphone</v-icon>
                            </div>
                            <div class="components_basic_item_text">
                                Background
                            </div>
                            <v-file-input
                                    accept="image/*"
                                    show-size
                                    dense
                                    prepend-icon=""
                                    ref="uploaderBg"
                                    class="d-none"
                                    outlined
                                    @change="onFileChangeImageBackground"
                            ></v-file-input>
                        </div>
                        <div class="components_basic_item" @click="handleFileImport">
                            <div class="components_basic_item_img">
                                <v-icon>mdi-image</v-icon>
                            </div>
                            <div class="components_basic_item_text">
                                Image
                            </div>
                            <v-file-input
                                    accept="image/*"
                                    show-size
                                    dense
                                    prepend-icon=""
                                    ref="uploader"
                                    class="d-none"
                                    outlined
                                    @change="onFileChangeImage"
                            ></v-file-input>
                        </div>
                        <div class="components_basic_item" @click="addComponent('text')">
                            <div class="components_basic_item_img">
                                <v-icon>mdi-format-text</v-icon>
                            </div>
                            <div class="components_basic_item_text">
                                Text
                            </div>
                        </div>
                        <div class="components_basic_item" @click="addComponent('qrcode')">
                            <div class="components_basic_item_img">
                                <v-icon>mdi-qrcode</v-icon>
                            </div>
                            <div class="components_basic_item_text">
                                Qr code
                            </div>
                        </div>
                    </div>
                </div>
                <div class="components_basic">
                    <label class="label" @click="showStructure">Structure <v-icon>{{componentsStructureShow?'mdi-chevron-up':'mdi-chevron-down'}}</v-icon></label>
                    <div class="components_basic_list" v-if="componentsStructureShow">
                        <div v-for="(component,componentIndex) in addedComponents" :key="component.id" style="position: relative;" @click="selectComponent(component)">
                            <div class="components_basic_item_delete" @click.stop="deleteAddedComponent(componentIndex)">
                                <v-icon small color="red">mdi-close</v-icon>
                            </div>
                            <div  class="components_basic_item"  v-if="component.type === 'background'">
                                <div class="components_basic_item_img">
                                    <v-icon>mdi-cellphone</v-icon>
                                </div>
                                <div class="components_basic_item_text">
                                    Background
                                </div>
                            </div>
                            <div class="components_basic_item"   v-if="component.type === 'image'">
                                <div class="components_basic_item_img">
                                    <v-icon>mdi-image</v-icon>
                                </div>
                                <div class="components_basic_item_text">
                                    Image
                                </div>
                            </div>
                            <div class="components_basic_item"  v-if="component.type === 'text'">
                                <div class="components_basic_item_img">
                                    <v-icon>mdi-format-text</v-icon>
                                </div>
                                <div class="components_basic_item_text">
                                    Text
                                </div>
                            </div>
                            <div class="components_basic_item"  v-if="component.type === 'qrcode'">
                                <div class="components_basic_item_img">
                                    <v-icon>mdi-qrcode</v-icon>
                                </div>
                                <div class="components_basic_item_text">
                                    Qr code
                                </div>
                            </div>
                        </div>
                        <div v-if="!addedComponents.length">
                            no added components
                        </div>
                    </div>

                </div>
            </div>
            <div class="preview">
                <div id="previewSticker" :style="`background:${bg?'url('+bg+')':''};background-repeat:no-repeat;background-size:100% 100%;`">
                    <div v-for="block in addedComponents" :key="block.id" style="position:absolute;" :style="`width:${block.styles.width}px;height:${block.styles.height}px;top:${block.styles.top}px;left:${block.styles.left}px;z-index:${block.styles.zIndex};`">
                        <div v-if="block.type === 'text'" contenteditable="true" @mousemove="moveBlock"  @mousedown="startMoveBlock" @mouseup="endMoveBlock"  @click="selectComponent(block)" :style="`outline:${selectedComponent.id === block.id?'auto 5px -webkit-focus-ring-color':'none'};cursor:pointer;width:${block.styles.width}px;height:${block.styles.height}px;font-style:${block.styles.fontStyle};top:${block.styles.top}px;left:${block.styles.left}px;z-index:${block.styles.zIndex};color:${block.styles.fontColor};font-size:${block.styles.fontSize}px;font-weight:${block.styles.fontWeight};font-family:${block.styles.fontFamily};font-style;${block.styles.fontStyle};`">
                        </div>
                        <div  v-if="block.type === 'image'" @mousemove="moveBlock"  @mousedown="startMoveBlock" @mouseup="endMoveBlock"  @click="selectComponent(block)" :style="`background:url(${block.content});background-size:100% 100%;background-repeat:no-repeat;outline:${selectedComponent.id === block.id?'auto 5px -webkit-focus-ring-color':'none'};cursor:pointer;font-style:${block.styles.fontStyle};width:${block.styles.width}px;height:${block.styles.height}px;top:${block.styles.top}px;left:${block.styles.left}px;z-index:${block.styles.zIndex};`">
                        </div>
                        <div v-if="selectedComponent.id === block.id" style="cursor:pointer;width:10px;height:10px;position: absolute;left:calc(50% - 2.5px);top:-7px;background: #000;" @mousemove.stop="changeHeightTopBlock"  @mousedown.stop="startChangeHeightTopBlock" @mouseup.stop="endChangeHeightTopBlock"></div>
                        <div v-if="selectedComponent.id === block.id" style="cursor:pointer;width:10px;height:10px;position: absolute;left:calc(50% - 2.5px);background: #000;" :style="`top:${block.styles.height-2}px;`" @mousemove.stop="changeHeightDownBlock"  @mousedown.stop="startChangeHeightDownBlock" @mouseup.stop="endChangeHeightUpMoveBlock"></div>
                        <div  v-if="selectedComponent.id === block.id" style="cursor:pointer;width:10px;height:10px;position: absolute;left:-7px;top:calc(50% - 2.5px);background: #000;" @mousemove.stop="changeWidthLeftBlock"  @mousedown.stop="startChangeWidthLeftBlock" @mouseup.stop="endChangeWidthLeftBlock"></div>
                        <div v-if="selectedComponent.id === block.id" style="cursor:pointer;width:10px;height:10px;position: absolute;top:calc(50% - 2.5px);background: #000;" :style="`left:${block.styles.width-2}px;`" @mousemove.stop="changeWidthRightBlock"  @mousedown.stop="startChangeWidthRightBlock" @mouseup.stop="endChangeWidthRightBlock"></div>
                    </div>
                </div>
            </div>
            <div class="styles">
                <div class="styles_block" v-if="selectedComponent">
                    <label class="label" style="margin-bottom: 15px;font-weight: bold;display: block;">Block settings</label>
                    <div class="styles_block_list">
                        <div class="styles_block_item">
                            <label class="label"><v-icon>mdi-checkbox-multiple-blank-outline</v-icon>Z-index <b>{{selectedComponent.styles.zIndex}}</b></label>
                            <v-slider v-model="selectedComponent.styles.zIndex" :min="0" :max="1000"  hide-details></v-slider>
                        </div>
                        <div class="styles_block_item">
                            <label class="label"><v-icon>mdi-dots-vertical</v-icon>Height <b>{{selectedComponent.styles.height}} px</b></label>
                            <v-slider v-model="selectedComponent.styles.height" :min="0" :max="1000" hide-details></v-slider>
                        </div>
                        <div class="styles_block_item">
                            <label class="label"><v-icon>mdi-dots-horizontal</v-icon>Width <b>{{selectedComponent.styles.width}} px</b></label>
                            <v-slider v-model="selectedComponent.styles.width" :min="0" :max="1000"  hide-details></v-slider>
                        </div>
                        <div class="styles_block_item">
                            <label class="label"><v-icon>mdi-arrow-right</v-icon>Left <b>{{selectedComponent.styles.left}} px</b></label>
                            <v-slider v-model="selectedComponent.styles.left" :min="0" :max="1000"  hide-details></v-slider>
                        </div>
                        <div class="styles_block_item">
                            <label class="label"><v-icon>mdi-arrow-down</v-icon>Top <b>{{selectedComponent.styles.top}} px</b></label>
                            <v-slider v-model="selectedComponent.styles.top" :min="0" :max="1000"  hide-details></v-slider>
                        </div>
                    </div>
                    <div v-if="selectedComponent.type === 'text'">
                        <label class="label" style="margin-bottom: 15px;font-weight: bold;display: block;">Text settings</label>
                        <div class="styles_block_list">
                            <div class="styles_block_item">
                                <label class="label"><v-icon>mdi-format-size</v-icon>Size <b>{{selectedComponent.styles.fontSize}} px</b></label>
                                <v-slider v-model="selectedComponent.styles.fontSize" :min="0" :max="50"  hide-details></v-slider>
                            </div>
                            <div class="styles_block_item">
                                <label class="label"><v-icon>mdi-format-size</v-icon>Weight <b>{{selectedComponent.styles.fontWeight}} px</b></label>
                                <v-slider v-model="selectedComponent.styles.fontWeight" :step="100" :min="0" :max="1000"  hide-details></v-slider>
                            </div>
                            <div class="styles_block_item_flex">
                                <label class="label"><v-icon>mdi-pencil</v-icon>Color</label>
                                <v-menu
                                        top
                                        nudge-left="16"
                                        nudge-bottom="98"
                                        :close-on-content-click="false"
                                        v-model="show_text_color"
                                >
                                    <template v-slot:activator="{ on }">
                                        <div
                                                class="colorpicker"
                                                :style="`background-color:${selectedComponent.styles.fontColor}`"
                                                style="margin-right: 10px;height:20px;width:20px;"
                                                v-on="on"
                                        />
                                    </template>
                                    <v-card>
                                        <v-card-text class="pa-0">
                                            <v-color-picker
                                                    v-model="selectedComponent.styles.fontColor"
                                                    flat
                                            />
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </div>
                        <div class="styles_block_item_flex">
                            <label class="label">
                               Font Family
                            </label>
                            <v-select
                                    flat
                                    dense
                                    outlined
                                    hide-details
                                    placeholder="Select Font Family"
                                    :items="fontFamilyTypes"
                                    item-text="label"
                                    item-value="value"
                                    class="canvas__select"
                                    v-model="selectedComponent.styles.fontFamily"
                            >
                                <template v-slot:selection="{ item }">
                                    <div
                                            :style="`font-family:'${item.value}',sans-serif`"
                                    >
                                        {{ item.label }}
                                    </div>
                                </template>
                                <template v-slot:item="{ item }">
                                    <div
                                            :style="`font-family:'${item.value}',sans-serif`"
                                    >
                                        {{ item.label }}
                                    </div>
                                </template>
                            </v-select>
                        </div>
                            <div class="styles_block_item_flex">
                                <label class="label">
                                    Font Styles
                                </label>
                                <v-radio-group
                                        row
                                        v-model="selectedComponent.styles.fontStyle"
                                >
                                    <v-radio
                                            v-for="type in fontStyleTypes"
                                            :key="type.id"
                                            :label="type.label"
                                            :value="type.value"
                                    >
                                    </v-radio>
                                </v-radio-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "StickerConstructor",
        data(){
          return{
            componentsBasicShow:true,
            componentsStructureShow:true,
            addedComponents:[],
            selectedComponent:null,
            show_text_color:false,
            fontStyleTypes: [
                  { id: 1, value: "normal", label: "Normal" },
                  { id: 0, value: "italic", label: "Italic" },
              ],
            fontFamilyTypes: [
              { id: 0, value: "Arial", label: "Arial" },
              { id: 1, value: "SF UI Text", label: "SF UI Text" },
              { id: 2, value: "Proxima Nova Bl", label: "Proxima Nova Bl" },
              { id: 3, value: "Myriad Pro", label: "Myriad Pro" },
              { id: 4, value: "HelveticaNeueCyr", label: "HelveticaNeueCyr" },
              { id: 5, value: "Gotham Pro", label: "Gotham Pro" },
              { id: 6, value: "Geometria", label: "Geometria" },
              { id: 7, value: "DIN Pro", label: "DIN Pro" },
              { id: 8, value: "Circe", label: "Circe" },
              { id: 9, value: "Bebas Neue", label: "Bebas Neue" },
              { id: 10, value: "Acrom", label: "Acrom" },
              { id: 11, value: "Inter", label: "Inter" },
          ],
              move:false,
              changeHeightDown:false,
              changeHeightDownCoordinates:{
                start:0,
                  end:0
              },
              changeHeightUp:false,
              changeHeightUpCoordinates:{
                  start:0,
                  end:0
              },
              changeWidthLeft:false,
              changeWidthLeftCoordinates:{
                  start:0,
                  end:0
              },
              changeWidthRight:false,
              changeWidthRightCoordinates:{
                  start:0,
                  end:0
              },
              coordinatesStart:{
                x:0,
                  y:0
              },

              coordinatesEnd:{
                x:0,
                  y:0
              },
              isSelecting:false,
              isSelectingBG:false,
              bg:null
          }
        },
        components:{

        },
        methods:{
            async onFileChangeImageBackground(file){
                if (file) {
                    try {
                        let formData = new FormData();
                        formData.append("image", file);
                        formData.append("file", file);
                        const response = await this.$axios.post("image-upload", formData);
                        this.bg = response.data;
                    } catch (e) {
                        console.log(e);
                    }
                }
            },
            handleFileImportBackground() {
                this.isSelectingBG = true;
                window.addEventListener(
                    "focus",
                    () => {
                        this.isSelectingBG = false;
                    },
                    { once: true }
                );
                this.$refs.uploaderBg.$refs.input.click();
            },
            async onFileChangeImage(file){
                if (file) {
                    try {
                        let formData = new FormData();
                        formData.append("image", file);
                        formData.append("file", file);
                        const response = await this.$axios.post("image-upload", formData);
                        this.addComponent('image',response.data);
                    } catch (e) {
                        console.log(e);
                    }
                }
            },
            handleFileImport() {
                this.isSelecting = true;
                window.addEventListener(
                    "focus",
                    () => {
                        this.isSelecting = false;
                    },
                    { once: true }
                );
                this.$refs.uploader.$refs.input.click();
            },
            changeWidthLeftBlock(e){
                if(!this.changeWidthLeft){
                    return false;
                }
                this.changeWidthLeftCoordinates.end= e.clientX;
                this.selectedComponent.styles.width += this.changeWidthLeftCoordinates.start - this.changeWidthLeftCoordinates.end;
                this.selectedComponent.styles.left += this.changeWidthLeftCoordinates.end - this.changeWidthLeftCoordinates.start;
                this.changeWidthLeftCoordinates.start = e.clientX;
            },
            startChangeWidthLeftBlock(e){
                this.changeWidthLeftCoordinates.start = e.clientX;
                this.changeWidthLeft = true;
            },
            endChangeWidthLeftBlock(e){
                this.changeWidthLeft = false;
            },

            changeWidthRightBlock(e){
                if(!this.changeWidthRight){
                    return false;
                }
                this.changeWidthRightCoordinates.end= e.clientX;
                this.selectedComponent.styles.width += this.changeWidthRightCoordinates.end - this.changeWidthRightCoordinates.start;
                this.changeWidthRightCoordinates.start = e.clientX;
            },
            startChangeWidthRightBlock(e){
                this.changeWidthRightCoordinates.start = e.clientX;
                this.changeWidthRight = true;
            },
            endChangeWidthRightBlock(e){
                this.changeWidthRight = false;
            },


            changeHeightTopBlock(e){
                if(!this.changeHeightUp){
                    return false;
                }
                this.changeHeightUpCoordinates.end= e.clientY;
                this.selectedComponent.styles.height += this.changeHeightUpCoordinates.start - this.changeHeightUpCoordinates.end;
                this.selectedComponent.styles.top += this.changeHeightUpCoordinates.end - this.changeHeightUpCoordinates.start;
                this.changeHeightUpCoordinates.start = e.clientY;
            },
            startChangeHeightTopBlock(e){
                this.changeHeightUpCoordinates.start = e.clientY;
                this.changeHeightUp = true;
            },
            endChangeHeightTopBlock(e){
                this.changeHeightUp = false;
            },



            changeHeightDownBlock(e){
                if(!this.changeHeightDown){
                    return false;
                }
                this.changeHeightDownCoordinates.end= e.clientY;
                this.selectedComponent.styles.height += this.changeHeightDownCoordinates.end - this.changeHeightDownCoordinates.start;
                this.changeHeightDownCoordinates.start = e.clientY;
            },
            startChangeHeightDownBlock(e){
                this.changeHeightDownCoordinates.start = e.clientY;
                this.changeHeightDown = true;
            },
            endChangeHeightUpMoveBlock(e){
                this.changeHeightDown = false;
            },
            startMoveBlock(e){
                this.coordinatesStart.x = e.clientX;
                this.coordinatesStart.y = e.clientY;
                this.move = true;
            },
            moveBlock(e){
                if(!this.move){
                    return false;
                }
                this.coordinatesEnd.y = e.clientY;
                this.coordinatesEnd.x = e.clientX;
                this.selectedComponent.styles.left += this.coordinatesEnd.x - this.coordinatesStart.x;
                this.selectedComponent.styles.top += this.coordinatesEnd.y - this.coordinatesStart.y;
                this.coordinatesStart.y = e.clientY;
                this.coordinatesStart.x = e.clientX;
            },
            endMoveBlock(e){
              this.move = false;
            },
            showComponents(){
                this.componentsBasicShow = !this.componentsBasicShow;
            },
            showStructure(){
                this.componentsStructureShow = !this.componentsStructureShow;
            },
            addComponent(type,content){
                const Component = {
                    id:this.addedComponents.length?this.addedComponents[this.addedComponents.length-1].id+1:0,
                    type:type,
                    content:content?content:'',
                    styles:{
                        zIndex:0,
                        left:0,
                        top:0,
                        width:200,
                        height:200
                    }
                }
                if(type === 'text'){
                    Component.styles = {
                        ...Component.styles,
                        fontSize:14,
                        fontStyle:'normal',
                        fontWeight:500,
                        fontColor:'#000000',
                        fontPosition:"left",
                        fontFamily:"Inter"
                    }
                }
                this.addedComponents.push(Component);
                this.selectedComponent = Component;
            },
            selectComponent(component){
              this.selectedComponent = component;
            },
            deleteAddedComponent(index){
                if(this.selectedComponent?.id === this.addedComponents[index].id){
                    this.selectedComponent = null;
                }
                this.addedComponents.splice(index,1);
            }

        }
    }
</script>

<style scoped lang="scss">
.container_editor{
    height:100%;
    width:100%;
    display: flex;
}
    .components{
        padding:15px;
        flex:1;
        height: 100%;
        background: #fff;

        &_basic{
            border-bottom:1px solid #ccc;
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;
            & .label{
                cursor: pointer;
                margin-bottom: 15px;
            }

            &_list{
                display: flex;
                flex-direction: column;
            }

            &_item{
                margin-bottom: 15px;
                cursor: pointer;
                border:1px solid #ddd;
                padding:10px;
                display: flex;
                align-items: center;
                &_img{
                    margin-right: 7px;
                }
            }
        }
    }
    .preview{
        flex:8;
        height: 100%;
        background-image: linear-gradient( 45deg, #f5f5f5 25%, transparent 0, transparent 75%, #f5f5f5 0 ), linear-gradient( 45deg, #f5f5f5 25%, transparent 0, transparent 75%, #f5f5f5 0 );
        background-position: 0 0, 13px 13px;
        background-size: 26px 26px;
        background-repeat: repeat;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .styles{
        flex:3;
        height: 100%;
        background: #fff;
        &_block{
            padding:15px;
            &_list{
                margin-bottom:30px;
            }
            &_item_flex{
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                & .label{
                    margin-right: 15px;
                    margin-bottom: 0;
                }
            }
        }
    }
    .components_basic_item_delete{
        position: absolute;
        top:5px;
        right: 5px;
        z-index: 12;
        cursor: pointer;
        &:hover{
            transform: scale(1.1);
        }
    }
    #previewSticker{
        background: #fff;
        width:800px;
        height:900px;
        position: relative;
    }
</style>